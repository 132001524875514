import {Injectable} from '@angular/core';
import {IOnlineTuitionSubject} from '../models/IOnlineTuitionSubject';
import {GlobalConstants} from '../shared/globalConstants';
import {HttpClient} from '@angular/common/http';
import {Http} from '@angular/http';
import {Observable} from 'rxjs';
import {IOnlineTuitionLevel} from '../models/IOnlineTuitionLevel';

@Injectable()
export class ImagesService {

  baseUrl: String = `${GlobalConstants.BASE_URL}/api/images`;

  constructor(private httpClient: HttpClient, private http: Http) {}

  uploadAdmin(payload: FormData): Observable<string> {
    return this.httpClient.post(`${this.baseUrl}/admin/upload`, payload, {responseType: 'text'});
  }

}
