import { Injectable } from '@angular/core';
import { ITeacher } from '../models/teacher.model';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from '../../../node_modules/rxjs';
import { GlobalConstants } from '../shared/globalConstants';
import { Http } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class TeacherService {

  teachers: ITeacher[];
  relatedTeachers: ITeacher[];
  baseUrl: String = GlobalConstants.BASE_URL;

  searchTeacherByNameSubject = new Subject();

  constructor(private httpClient: HttpClient, private http: Http) { }

  //PUBLIC SERVICES
  getTeachers(): Observable<any> {
    // return this.httpClient.get<ITeacher[]>(this.baseUrl + '/teachers', {
    //   observe: 'body',
    //   responseType: 'json'
    // });
    return this.http.get(this.baseUrl + '/teachers');
  }

  getTeacher(teacherId : number): Observable<any> {
    return this.http.get(this.baseUrl + '/teachers/' + teacherId);
  }

  addTeacher(formData: FormData): any{
    return this.httpClient.post<any>(this.baseUrl + '/teacher-registration', formData);
  }
}