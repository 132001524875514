import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TeacherService } from '../../services/teacher.service';
import { ITeacher } from '../../models/teacher.model';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-teacher-details',
  templateUrl: './teacher-details.component.html',
  styleUrls: ['./teacher-details.component.css']
})
export class TeacherDetailsComponent implements OnInit {

  teacher: ITeacher;
  safeURL;

  relatedTeachers: ITeacher[];

  constructor(private teacherService: TeacherService, private activatedRoute: ActivatedRoute, private location: Location, private _sanitizer: DomSanitizer, private spinner: NgxSpinnerService,  @Inject(PLATFORM_ID) private platformId: Object, public meta: Meta, public title: Title) { }

  ngOnInit() {
    this.spinner.show();

    let id: number;
    this.activatedRoute.params.subscribe(paramId => {
      id = paramId.id;

      this.teacherService.getTeacher(id).subscribe(
        (teacher) => {
          console.log(teacher);
          this.teacher = teacher.json();
          this.spinner.hide();

          this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(teacher.youtubeUrl);

          if (this.teacherService.teachers) {
            this.relatedTeachers = this.teacherService.relatedTeachers;

          }

          // GOOGLE META
          this.meta.updateTag({ name: 'description', content: this.teacher.shortDescription }); 
          this.title.setTitle( this.teacher.fullName + ' - TeachMandu');
          // FACEBOOK META
          this.meta.updateTag({ property: 'og:title', content: this.teacher.fullName + '- TeachMandu' });
          this.meta.updateTag({ property: 'og:type', content: 'article' });
          this.meta.updateTag({ property: 'og:image', content: 'https://res.cloudinary.com/dncitxbwc/image/upload/c_fill,h_150,w_150/' + this.teacher.url });
          // this.meta.updateTag({ property: 'og:url', content: this.teacher.url });
          this.meta.updateTag({ property: 'og:description', content: this.teacher.shortDescription });
        }
      );
      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo(0, 0);
      }
    }
    );
  }

  backClicked() {
    this.location.back();
  }
}