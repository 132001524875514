import {Injectable} from '@angular/core';
import {IOnlineTuitionSubject} from '../models/IOnlineTuitionSubject';
import {GlobalConstants} from '../shared/globalConstants';
import {HttpClient} from '@angular/common/http';
import {Http} from '@angular/http';
import {Observable} from 'rxjs';

@Injectable()
export class OnlineTuitionService {

  onlineTuitionSubjects: IOnlineTuitionSubject[];
  baseUrl: String = `${GlobalConstants.BASE_URL}/api/onlineTuition`;

  constructor(private httpClient: HttpClient, private http: Http) {}

  getAll(): Observable<IOnlineTuitionSubject[]> {
    return this.httpClient.get<IOnlineTuitionSubject[]>(`${this.baseUrl}/subjects`, {
      observe: 'body',
      responseType: 'json'
    });
  }

  getAllByLevel(levelId: number): Observable<IOnlineTuitionSubject[]> {
    return this.httpClient.get<IOnlineTuitionSubject[]>(`${this.baseUrl}/subjects/${levelId}/levels`, {
      observe: 'body',
      responseType: 'json'
    });
  }

  getOne(id: number): Observable<IOnlineTuitionSubject> {
    return this.httpClient.get<IOnlineTuitionSubject>(`${this.baseUrl}/subjects/${id}`, {
      observe: 'body',
      responseType: 'json'
    });
  }

  add(payload: IOnlineTuitionSubject): any {
    // console.log(payload);
    return this.httpClient.post<any>(`${this.baseUrl}/subjects`, payload);
  }

  update(id: number, payload: IOnlineTuitionSubject): any {
    return this.httpClient.put<any>(`${this.baseUrl}/subjects/${id}`, payload);
  }

  delete(id: number): any {
    return this.httpClient.delete<any>(`${this.baseUrl}/subjects/${id}`);
  }

  disable(id: number) {
    return this.httpClient.put<any>(`${this.baseUrl}/subjects/${id}/disable`, {});
  }
}
