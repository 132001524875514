/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewChild, ElementRef, NgZone, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ITeacher } from '../models/teacher.model';
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import { TeacherService } from '../services/teacher.service';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-land-page',
  templateUrl: './land-page.component.html',
  styleUrls: ['./land-page.component.css']
})
export class LandPageComponent implements OnInit {

  form: FormGroup;
  teachers : ITeacher[];
  @ViewChild('mapSearch') public searchElement: ElementRef;
  googleLocation;
  levelSelected: boolean = false;
  subjectsToList: string[] = [];

  //FILTER PARAMS
  lat: number;
  lng: number;
  googleLocationName: string;
  subject: string;
  level: string;
  price: string;


  constructor(private mpsAPILoader: MapsAPILoader, private ngZone: NgZone, private router: Router, private teacherService: TeacherService, @Inject(PLATFORM_ID) private platformId: Object, public meta: Meta, public title: Title) { }

  ngOnInit() {
    // GOOGLE META
    this.title.setTitle( 'TeachMandu');
    this.meta.updateTag({ name: 'description', content: 'TeachMandu is a perfect place for you to find a suitable home tutor from many professional teachers in Kathmandu, Nepal; get tutored for Below Class 9, SEE, +2 or A Levels. You can also register as a tutor here for free so student can view your profile.' });
    // FACEBOOK META
    this.meta.updateTag({ property: 'og:title', content: 'TeachMandu' });
    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:image', content: 'assets/images/landpage-bg.jpg' });
    // this.meta.updateTag({ property: 'og:url', content: this.teacher.url });
    this.meta.updateTag({ property: 'og:description', content: 'TeachMandu is a perfect place for you to find a suitable home tutor from many professional teachers in Kathmandu, Nepal; get tutored for Below Class 9, SEE, +2 or A Levels. You can also register as a tutor here for free so student can view your profile.' });

    this.form = new FormGroup({
      'location': new FormControl(null),
      'subject': new FormControl('Please Select'),
      'level': new FormControl('Please Select'),
      'price': new FormControl('All Prices')
    });

    if(this.teacherService.teachers){
      this.teachers = this.teacherService.teachers;
    } else {
      this.teacherService.getTeachers().subscribe(
        (teachers) => {
          this.teachers = teachers.json();
        },
        () => {},
        () => {
          this.teacherService.teachers = this.teachers;
        }
      );
    }

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }


    //GOOGLE MAP SCRIPT
    this.mpsAPILoader.load().then(
      () => {
        let autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, { types:[] });

        let boundGeolocation = {
          lat: 27.689465,
          lng: 85.323219
        }

        let circle = new google.maps.Circle({
          center: boundGeolocation,
          radius: 17236.21940499995
        });
        autocomplete.setBounds(circle.getBounds());

        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();

            if(place.geometry === undefined || place.geometry === null){
              return;
            }

            this.googleLocation = place;
          });
        });
      }
    );
  }

  levelChanged(){
    if(this.form.controls.level.value !== "Please Select"){
      this.levelSelected = true;

      if(this.form.controls.level.value === "Below Class 9" || this.form.controls.level.value === "SEE"){
        this.subjectsToList = ["Science", "Maths", "OPT Maths", "Account", "Computer", "Social", "English", "Nepali", "All the Above"];
      }

      if(this.form.controls.level.value === '+2' || this.form.controls.level.value === 'A Level'){
        this.subjectsToList = ['Physics', 'Chemistry', 'Maths', 'Biology', 'Account', 'Economics', 'Marketing', 'Rural Development', 'Computer', 'All the Above'];
      }

      if(this.form.controls.level.value === "Co-Curricular"){
        this.subjectsToList = ["Robotics", "Coding", "Dance", "Music", "Drama", "Art/Craft", "Material Arts", "Yoga", "ECA Co-ordinator", 'Coordinator', 'Lab Boy', 'Incharges'];
      }

      if(this.form.controls.level.value === "Sports"){
        this.subjectsToList = ["Football", "Cricket", "Swimming", "Chess", "Basketball", "Badminton"];
      }
    }

    if(this.form.controls.level.value === "Please Select"){
      this.levelSelected = false;
    }
  }

  onSubmit(){
    if(this.googleLocation){
      this.googleLocationName = this.googleLocation.name;
      this.lat = this.googleLocation.geometry.location.lat();
      this.lng = this.googleLocation.geometry.location.lng();
    }

    this.subject = this.form.controls.subject.value;
    if(this.subject === "Please Select" || this.subject === "All the Above"){
      this.subject = null;
    }
    this.level = this.form.controls.level.value;
    if(this.level === "Please Select"){
      this.level = null;
    }
    this.price = this.form.controls.price.value;
    if(this.price === "All Prices"){
      this.price = null;
    }

    // console.log({queryParams: {lat: this.lat, lng: this.lng, googleLocationName: this.googleLocationName, subject: this.subject, level: this.level, price: this.price}});

    this.router.navigate(['/filterPage'], {queryParams: {lat: this.lat, lng: this.lng, googleLocationName: this.googleLocationName, subject: this.subject, level: this.level, price: this.price}});
  }

  findYourOnlineClass() {
    this.router.navigate(['/online-class-registration-levels']);
  }

  registerForOnlineClass() {
    this.router.navigate(['/online-class-student-registration']);
  }
}
