import { CanActivate } from "@angular/router";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { TokenStorage } from "./token.storage";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";

@Injectable()
export class RoleGuardService implements CanActivate {
    
    constructor(private token: TokenStorage, private helper: JwtHelperService, private router: Router){}
    
    canActivate(route: ActivatedRouteSnapshot): boolean {
       const token = this.token.getToken();

       if(!token){
        this.router.navigate(['login']);
        return false;
       }
       const tokenPayload = this.helper.decodeToken(token);

        if(tokenPayload.scopes === route.data.role){
            return true;
        }
    }
}