import { Component, OnInit, ViewChild, ElementRef, NgZone, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.css']
})
export class AdvancedSearchComponent implements OnInit {

  form: FormGroup;
  @ViewChild('mapSearch') public searchElement: ElementRef;
  googleLocation;
  levelSelected: boolean = false;
  subjectsToList: string[] = [];

  //FILTER PARAMS
  lat: number;
  lng: number;
  googleLocationName: string;
  subject: string;
  level: string;
  price: string;

  constructor(private mpsAPILoader: MapsAPILoader, private ngZone: NgZone, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.form = new FormGroup({
      'location': new FormControl(null),
      'subject': new FormControl('Please Select'),
      'level': new FormControl('Please Select'),
      'price': new FormControl('All Prices')
    });

    //RETRIVING QUERY PARAMS
    this.activatedRoute.queryParams.subscribe(
      (params) => {
        this.lat = params.lat;
        this.lng = params.lng;
        this.googleLocationName = params.googleLocationName;
        this.subject = params.subject;
        this.level = params.level;
        this.price = params.price;

        //SETTING QUERY PARAMS
        if(this.googleLocationName)
          this.form.controls.location.setValue(this.googleLocationName);
        if(this.subject){
          this.form.controls.subject.setValue(this.subject);
        }
        if(this.level)
          this.form.controls.level.setValue(this.level);
          this.levelSelected = true;
          this.levelChanged();
        if(this.price)
          this.form.controls.price.setValue(this.price);
      }
    );


    //GOOGLE MAP SCRIPT
    this.mpsAPILoader.load().then(
      () => {
        let autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, { types: [] });

        let boundGeolocation = {
          lat: 27.689465,
          lng: 85.323219
        }

        let circle = new google.maps.Circle({
          center: boundGeolocation,
          radius: 17236.21940499995
        });
        autocomplete.setBounds(circle.getBounds());

        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();

            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            this.googleLocation = place;
          });
        });
      }
    );
  }

  levelChanged() {
    if (this.form.controls.level.value !== "Please Select") {
      this.levelSelected = true;

      if (this.form.controls.level.value === "Below Class 9" || this.form.controls.level.value === "SEE") {
        this.subjectsToList = ["Science", "Maths", "OPT Maths", "All the Above"];
      }

      if (this.form.controls.level.value === "+2" || this.form.controls.level.value === "A Level") {
        this.subjectsToList = ["Physics", "Chemistry", "Maths", "Biology", "All the Above"];
      }
    }

    if (this.form.controls.level.value === "Please Select") {
      this.levelSelected = false;
    }
  }

  onSubmit(){
    if(this.googleLocation){
      this.googleLocationName = this.googleLocation.name;
      this.lat = this.googleLocation.geometry.location.lat();
      this.lng = this.googleLocation.geometry.location.lng();
    }

    this.subject = this.form.controls.subject.value;
    if(this.subject === "Please Select"){
      this.subject = null;
    }
    this.level = this.form.controls.level.value;
    if(this.level === "Please Select"){
      this.level = null;
    }
    this.price = this.form.controls.price.value;
    if(this.price === "All Prices"){
      this.price = null;
    }

    
    this.router.navigate(['filterPage'], {queryParams: {lat: this.lat, lng: this.lng, googleLocationName: this.googleLocationName, subject: this.subject, level: this.level, price: this.price}});
  }


}
