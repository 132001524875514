import { Component, OnInit, Inject } from '@angular/core';
import { ITeacher } from '../../models/teacher.model';
import { TeacherService } from '../../services/teacher.service';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/debounceTime';
import { NgxSpinnerService } from 'ngx-spinner';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-teachers-list',
  templateUrl: './teachers-list.component.html',
  styleUrls: ['./teachers-list.component.css']
})
export class TeachersListComponent implements OnInit {
  // title: string = "List of Teachers";
  teachers: ITeacher[];
  filteredTeachers: ITeacher[];
  teacherSearchName: string;

  loadingTeachersPerResquest = 10;
  filteredTeachersPerRequest: ITeacher[];
  loadedRequest: number;
  showLoadMoreButton = true;
  
  //FILTER PARAMS
  lat: number;
  lng: number;
  googleLocationName: string;
  subject: string;
  level: string;
  price: string;

  constructor(private teacherService: TeacherService, private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService,  @Inject(PLATFORM_ID) private platformId: Object, public meta: Meta, public title: Title) { }

  ngOnInit() {
    // GOOGLE META
    this.title.setTitle( 'Filter Page - TeachMandu');
    this.meta.updateTag({ name: 'description', content: 'TeachMandu is a perfect place for you to find a suitable home tutor from many professional teachers in Kathmandu, Nepal; get tutored for Below Class 9, SEE, +2 or A Levels. You can also register as a tutor here for free so student can view your profile.' }); 
    // FACEBOOK META
    this.meta.updateTag({ property: 'og:title', content: 'Filter Page - TeachMandu' });
    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:image', content: 'assets/images/landpage-bg.jpg' });
    // this.meta.updateTag({ property: 'og:url', content: this.teacher.url });
    this.meta.updateTag({ property: 'og:description', content: 'TeachMandu is a perfect place for you to find a suitable home tutor from many professional teachers in Kathmandu, Nepal; get tutored for Below Class 9, SEE, +2 or A Levels. You can also register as a tutor here for free so student can view your profile.' });

    if (this.teacherService.teachers) {
      this.teachers = this.teacherService.teachers;
      this.afterGettingTeachers();
    } else {
      this.spinner.show();
      this.teacherService.getTeachers().subscribe(
        (teachers) => {
          this.teachers = teachers.json();
        },
        () => { },
        () => {
          this.spinner.hide();
          this.teacherService.teachers = this.teachers;
          this.afterGettingTeachers();
        }
      );
    }
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  afterGettingTeachers() {
    //RETRIVING QUERY PARAMS
    // this.activatedRoute.queryParams.subscribe(
      this.activatedRoute.queryParams.forEach(
      (params) => {
        
        this.lat = params.lat;
        this.lng = params.lng;
        this.googleLocationName = params.googleLocationName;
        this.subject = params.subject;
        this.level = params.level;
        this.price = params.price;
        this.teacherSearchName = params.teacherName;

        if(this.teacherSearchName){
          this.filterTeachersByName();
        } else {
          //FILTER TEACHERS ACCORDING TO QUERY PARAMS
          this.filterTeachers();
        }
      }
    );

  }

  filterTeachers() {
    this.loadedRequest = 0;
    this.showLoadMoreButton = true;
  

    //Romdomizing the Teachers Array
    for (let i = this.teachers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.teachers[i], this.teachers[j]] = [this.teachers[j], this.teachers[i]];
    }

    this.filteredTeachers = this.teachers;

    // PRICE FILTER
    if(this.price){
      let priceMin: number;
      let priceMax: number;
      if(this.price === "Below Rs. 5,000"){
        priceMin = 0;
        priceMax = 5000;
      }
      if(this.price === "Below Rs. 10,000"){
        priceMin = 0;
        priceMax = 10000;
      }
      if(this.price === "Rs. 10,000 - 20,000"){
        priceMin = 10000;
        priceMax = 20000;
      }
      if(this.price === "Above Rs. 20,000"){
        priceMin = 20000;
        priceMax = 100000;
      }
      this.filteredTeachers = this.teachers.filter(teacher => teacher.price >= priceMin && teacher.price <= priceMax);
    }

    //IF BOTH LEVEL AND SUBJECT IS SELECTED
    let sujbectIsSelected = false;
    if(this.subject && this.level){
      let tempFilteredTeachers : ITeacher[] = [];
      sujbectIsSelected = true;
      this.filteredTeachers.forEach(teacher => {
        teacher.subjectsTaughtDetailed.forEach(subject => {
          if(subject.qualification === this.level && subject.subject === this.subject){
            tempFilteredTeachers.push(teacher);
          }
        });
      });
      this.filteredTeachers = tempFilteredTeachers;
    }
    
    //IF ONLY LEVEL IS SELECTED
    if(this.level && !sujbectIsSelected){
      let tempFilteredTeachers : ITeacher[] = [];
      this.filteredTeachers.forEach(teacher => {
        let containsLevel = false;
        teacher.subjectsTaughtDetailed.forEach(subject => {
          if(subject.qualification === this.level){
            containsLevel = true;
          }
        });
        if(containsLevel){
          tempFilteredTeachers.push(teacher);
        }
      });
      this.filteredTeachers = tempFilteredTeachers;
    }




    //LOCATION FILTER
    if(this.googleLocationName){
      this.filteredTeachers.forEach(teacher => {
        // teacher.distance = this.calculateDistance(teacher.lat, teacher.lng, this.lat, this.lng);
        teacher.distance = this.pythagoras(teacher.lat, teacher.lng, this.lat, this.lng);
      });
      this.filteredTeachers = this.filteredTeachers.sort((teacherA: ITeacher, teacherB: ITeacher) => {
        if(teacherA.distance > teacherB.distance) return 1;
        if(teacherA.distance < teacherB.distance) return -1;
        return 0;
      });
    }

    //SLICING THE TEACHERS PER PAGE
    this.slicingTeachersPerRequest();
    
    
  }

  filterTeachersByName(){
    this.filteredTeachers = this.teachers.filter(
      (teacher) => {
        if(teacher.fullName){
          return teacher.fullName.toLowerCase().includes(this.teacherSearchName.toLowerCase());
        }
      }
    );
    this.filteredTeachersPerRequest = this.filteredTeachers;
  }

  pythagoras(lat1, lon1, lat2, lon2): number{
    return Math.sqrt(Math.pow(lat2 - lat1, 2) + Math.pow(lon2 - lon1, 2)) * 100 * 1.4;
  }

  slicingTeachersPerRequest(){
    this.loadedRequest += this.loadingTeachersPerResquest;
    this.filteredTeachersPerRequest = this.filteredTeachers.slice(0, this.loadedRequest);

    // SETTING RELATED TEACHERS FOR FUTURE
    this.teacherService.relatedTeachers = this.filteredTeachersPerRequest;

    if(this.filteredTeachersPerRequest.length >= this.filteredTeachers.length){
      this.showLoadMoreButton = false;
    }
  }


  // calculateDistance(lat1, lon1, lat2, lon2): number{
  //   let earthRadiusKm = 6371;

  //   let dLat = this.degreesToRadians(lat2-lat1);
  //   let dLon = this.degreesToRadians(lon2-lon1);

  //   lat1 = this.degreesToRadians(lat1);
  //   lat2 = this.degreesToRadians(lat2);

  //   let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
  //           Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  //   let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  //   return earthRadiusKm * c;
  // }

  // degreesToRadians(degrees) {
  //   return degrees * Math.PI / 180;
  // }

}
