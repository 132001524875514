import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../services/auth.service';
import { TokenStorage } from '../services/token.storage';
import { TeacherService } from '../services/teacher.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  showLogout: boolean = false;
  teacherName: string;

  constructor(private authService: AuthenticationService, private tokenStorage: TokenStorage, private teacherService: TeacherService, private router: Router) { }

  ngOnInit() {
    //For Authenticated state with refresh button pressed
    if(this.authService.authenticated){
      this.showLogout = true;
    }

    //For Authenticated state for application flow
    this.authService.authenticatedEmitter.subscribe(
      (data: boolean) => {
        this.showLogout = data;
      }
    );
  }

  searchTeacherByName(){
    this.router.navigate(['/filterPage'], {queryParams: {teacherName: this.teacherName}});
  }

  onLogoutClick(){
    this.tokenStorage.signOut();
    this.authService.changeAuthentication();
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      this.router.navigate(['/filterPage'], {queryParams: {teacherName: this.teacherName}});
    }
  }

}
