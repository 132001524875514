import {Injectable} from '@angular/core';
import {IOnlineTuitionSubject} from '../models/IOnlineTuitionSubject';
import {GlobalConstants} from '../shared/globalConstants';
import {HttpClient} from '@angular/common/http';
import {Http} from '@angular/http';
import {Observable} from 'rxjs';
import {IOnlineTuitionLevel} from '../models/IOnlineTuitionLevel';

@Injectable()
export class OnlineTuitionLevelsService {

  onlineTuitionLevels: IOnlineTuitionLevel[];
  baseUrl: String = `${GlobalConstants.BASE_URL}/api/onlineTuition`;

  constructor(private httpClient: HttpClient, private http: Http) {}

  getAll(): Observable<IOnlineTuitionLevel[]> {
    return this.httpClient.get<IOnlineTuitionLevel[]>(`${this.baseUrl}/levels`, {
      observe: 'body',
      responseType: 'json'
    });
  }

  getOne(id: number): Observable<IOnlineTuitionLevel> {
    return this.httpClient.get<IOnlineTuitionLevel>(`${this.baseUrl}/levels/${id}`, {
      observe: 'body',
      responseType: 'json'
    });
  }

  add(payload: IOnlineTuitionLevel): any {
    // console.log(payload);
    return this.httpClient.post<any>(`${this.baseUrl}/levels`, payload);
  }

  update(id: number, payload: IOnlineTuitionLevel): any {
    return this.httpClient.put<any>(`${this.baseUrl}/levels/${id}`, payload);
  }

  delete(id: number): any {
    return this.httpClient.delete<any>(`${this.baseUrl}/levels/${id}`);
  }

  disable(id: number) {
    return this.httpClient.put<any>(`${this.baseUrl}/levels/${id}/disable`, {});
  }
}
