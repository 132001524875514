import { Component, OnInit } from '@angular/core';
import {GlobalConstants} from '../../shared/globalConstants';
import {IOnlineTuitionSubject} from '../../models/IOnlineTuitionSubject';
import {OnlineTuitionService} from '../../services/onlineTuition.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-online-tuition-subjects',
  templateUrl: './online-tuition-subjects.component.html',
  styleUrls: ['./online-tuition-subjects.component.css']
})
export class OnlineTuitionSubjectsComponent implements OnInit {

  onlineTuitionLevelId: number;
  iOnlineTuitionSubjects: IOnlineTuitionSubject[];
  colors = GlobalConstants.colors;
  loading = false;
  error = false;

  constructor(private onlineTuitionService: OnlineTuitionService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.loading = true;
    this.activatedRoute.queryParams.forEach(
      (params) => {
        this.onlineTuitionLevelId = params.id;
        this.onlineTuitionService.getAllByLevel(this.onlineTuitionLevelId).subscribe(subjects => {
            this.iOnlineTuitionSubjects = subjects;
            this.loading = false;
          },
          error => {
            this.error = true;
            this.loading = false;
          }
        );
      }
    );
  }

  onSelect(subject: IOnlineTuitionSubject) {
    this.router.navigate(['/online-class-registration-days'], {queryParams: subject});
  }
}
