import { Component, OnInit } from '@angular/core';
import {OnlineTuitionLevelsService} from '../../services/onlineTuitionLevels.service';
import {Router} from '@angular/router';
import {IOnlineTuitionLevel} from '../../models/IOnlineTuitionLevel';
import {GlobalConstants} from '../../shared/globalConstants';

@Component({
  selector: 'app-online-tuition-levels',
  templateUrl: './online-tuition-levels.component.html',
  styleUrls: ['./online-tuition-levels.component.css']
})
export class OnlineTuitionLevelsComponent implements OnInit {

  onlineTuitionLevels: IOnlineTuitionLevel[];
  colors = GlobalConstants.colors;
  loading = true;
  error = false;

  constructor(private onlineTuitionLevelsService: OnlineTuitionLevelsService, private router: Router) { }

  ngOnInit() {
    this.fetchData();
  }

  private fetchData() {
    this.loading = true;
    this.onlineTuitionLevelsService.getAll().subscribe(levels => {
        this.onlineTuitionLevels = levels;
        this.error = false;
        this.loading = false;
      },
      error => {
        this.error = true;
        this.loading = false;
      }
    );
  }

  onSelect(level: IOnlineTuitionLevel) {
    this.router.navigate(['/online-class-registration-subjects'], {queryParams: level});
  }

  onRefresh() {
    this.fetchData();
  }

}
