import {Component, OnInit} from '@angular/core';
import {IOnlineTuitionSubject} from '../../models/IOnlineTuitionSubject';
import {GlobalConstants} from '../../shared/globalConstants';
import {ActivatedRoute, Router} from '@angular/router';
import {OnlineTuitionService} from '../../services/onlineTuition.service';
import {IOnlineTuitionDays} from '../../services/IOnlineTuitionDays';

@Component({
  selector: 'app-online-tuition-days',
  templateUrl: './online-tuition-days.component.html',
  styleUrls: ['./online-tuition-days.component.css']
})
export class OnlineTuitionDaysComponent implements OnInit {

  onlineTuitionSubject: IOnlineTuitionSubject;
  colors = GlobalConstants.colors;
  loading = false;
  error = false;

  constructor(private onlineTuitionService: OnlineTuitionService, private router: Router, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.loading = true;
    this.activatedRoute.queryParams.forEach(
      (params) => {
        this.onlineTuitionService.getOne(params.id).subscribe(subject => {
          this.onlineTuitionSubject = subject;
          this.loading = false;
        });
      }
    );
  }

  onSelect(day: IOnlineTuitionDays) {
    window.open(day.classUrl, '_system');
  }

  registerForOnlineClass() {
    this.router.navigate(['/online-class-student-registration'], {queryParams: {subject: this.onlineTuitionSubject.title, level: this.onlineTuitionSubject.onlineTuitionLevel.title}});
  }

}
