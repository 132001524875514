import { Component } from '@angular/core';
import { TokenStorage } from './services/token.storage';
import { AuthenticationService } from './services/auth.service';
import { TeacherService } from './services/teacher.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  
  
  title = 'Teacher Nepal';

  constructor(private tokenStorage: TokenStorage, private authService: AuthenticationService, private teacherService: TeacherService){}

  ngOnInit() {
    if(this.tokenStorage.getToken()){
      this.authService.changeAuthentication();
    }
  }
}