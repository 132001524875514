import { Routes } from '@angular/router';
import { TeachersListComponent } from './app/teacher/teachers-list/teachers-list.component';
import { TeacherDetailsComponent } from './app/teacher/teacher-details/teacher-details.component';
import { LoginComponent } from './app/cms/login/login.component';
import { RoleGuardService as RoleGuard} from './app/services/role-guard.service';
import { LandPageComponent } from './app/land-page/land-page.component';
import { TeacherRegistrationComponent } from './app/teacher/teacher-registration/teacher-registration.component';
import {OnlineTuitionLevelsComponent} from './app/onlineTuitionClasses/online-tuition-levels/online-tuition-levels.component';
import {OnlineTuitionSubjectsComponent} from './app/onlineTuitionClasses/online-tuition-subjects/online-tuition-subjects.component';
import {OnlineTuitionDaysComponent} from './app/onlineTuitionClasses/online-tuition-days/online-tuition-days.component';
import {OnlineTuitionStudentRegistrationComponent} from './app/onlineTuitionClasses/online-tuition-student-registration/online-tuition-student-registration.component';
import {AccountDeletionInfoComponent} from './app/cms/accountDeletionInfo/accountDeletionInfo';


export const appRoutes: Routes = [
  {path: '', component: LandPageComponent},
  {path: 'filterPage', component: TeachersListComponent},
  {path: 'teacher/:id', component: TeacherDetailsComponent},
  {path: 'login', component: LoginComponent},
  {path: 'account-deletion-info', component: AccountDeletionInfoComponent},
  {path: 'teacher-registration', component: TeacherRegistrationComponent},
  {path: 'online-class-registration-levels', component: OnlineTuitionLevelsComponent},
  {path: 'online-class-registration-subjects', component: OnlineTuitionSubjectsComponent},
  {path: 'online-class-registration-days', component: OnlineTuitionDaysComponent},
  {path: 'online-class-student-registration', component: OnlineTuitionStudentRegistrationComponent},
  {path: 'adminDashboard', loadChildren: './cms/admin/admin.module#AdminModule', canActivate: [RoleGuard], data: {role: 'ROLE_ADMIN'}},
  {path: 'teacherDashboard', loadChildren: './cms/teacher/teacher.module#TeacherModule', canActivate: [RoleGuard], data: {role: 'ROLE_USER'}}
];
