export class GlobalConstants {
  // public static BASE_URL = 'http://localhost:8080';
  public static BASE_URL = 'https://api.teachmandu.com';

  public static colors = [
    {color: '#1abc9c', text: 'black'},
    {color: '#f1c40f', text: 'black'},
    {color: '#2ecc71', text: 'black'},
    {color: '#e67e22', text: 'black'},
    {color: '#3498db', text: 'black'},
    {color: '#e74c3c', text: 'black'},
  ];
}
