import {Injectable} from '@angular/core';
import {IOnlineTuitionSubject} from '../models/IOnlineTuitionSubject';
import {GlobalConstants} from '../shared/globalConstants';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {OnlineRegisteredStudent} from '../models/onlineRegisteredStudent';
import {IOnlineTuitionLevel} from '../models/IOnlineTuitionLevel';

@Injectable()
export class OnlineClassStudentRegistrationService {

  baseUrl = `${GlobalConstants.BASE_URL}/api/onlineTuition/registeredStudent`;

  constructor(private httpClient: HttpClient) {}

  getAll(): Observable<OnlineRegisteredStudent[]> {
    return this.httpClient.get<OnlineRegisteredStudent[]>(`${this.baseUrl}`, {
      observe: 'body',
      responseType: 'json'
    });
  }

  getOne(id: number): Observable<OnlineRegisteredStudent> {
    return this.httpClient.get<OnlineRegisteredStudent>(`${this.baseUrl}/${id}`, {
      observe: 'body',
      responseType: 'json'
    });
  }

  post(payload: OnlineRegisteredStudent): any {
    return this.httpClient.post<any>(`${this.baseUrl}`, payload);
  }

  put(payload: OnlineRegisteredStudent): any {
    return this.httpClient.put<any>(`${this.baseUrl}/${payload.id}`, payload);
  }

  delete(id: number) {
    return this.httpClient.delete<any>(`${this.baseUrl}/${id}`);
  }

}
