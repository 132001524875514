import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ITeacher } from '../../models/teacher.model';
import { MapsAPILoader } from '@agm/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TeacherService } from '../../services/teacher.service';

@Component({
  selector: 'app-teacher-registration',
  templateUrl: './teacher-registration.component.html',
  styleUrls: ['./teacher-registration.component.css']
})
export class TeacherRegistrationComponent implements OnInit {

  form: FormGroup;
  teacher: ITeacher = {};
  formData: FormData = new FormData();
  qualifications: FormArray;
  subjectsTaughtDetailed: FormArray;
  isAvailable = true;
  userNameIsNotUnique = false;
  @ViewChild('mapSearch') public searchElement: ElementRef;
  teacherLocation;

  imageFileSelectedForValidation = false;

  @ViewChild('UserImage') UserImage;
  @ViewChild('location') location: ElementRef;

  constructor(private teacherService: TeacherService, private formBuilder: FormBuilder, private mpsAPILoader: MapsAPILoader, private ngZone: NgZone, private router: Router, private toastrService: ToastrService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.form = new FormGroup({
      'fullName': new FormControl(null, [Validators.required]),
      'location': new FormControl(null, [Validators.required]),
      'highestDegree': new FormControl(null, [Validators.required]),
      'price': new FormControl(null, [Validators.required]),
      'phoneNumber': new FormControl(null, [Validators.required]),
      'shortDescription': new FormControl(null, [Validators.required]),
      'aboutMe': new FormControl(null, [Validators.required]),
      'aboutMySessions': new FormControl(null, [Validators.required]),
      qualifications: this.formBuilder.array([this.createQualification()]),
      subjectsTaughtDetailed: this.formBuilder.array([this.createSubjectsTaughtDetailed()]),
      isAvailable: new FormControl(true),
      availabilityPre10: new FormArray([
        new FormControl(true),
        new FormControl(true),
        new FormControl(true),
        new FormControl(true),
        new FormControl(true),
        new FormControl(true),
        new FormControl(true)
      ]),
      availabilityFrom10: new FormArray([
        new FormControl(true),
        new FormControl(true),
        new FormControl(true),
        new FormControl(true),
        new FormControl(true),
        new FormControl(true),
        new FormControl(true)
      ]),
      availabilityPost10: new FormArray([
        new FormControl(true),
        new FormControl(true),
        new FormControl(true),
        new FormControl(true),
        new FormControl(true),
        new FormControl(true),
        new FormControl(true)
      ]),
    });
  }

  createQualification(): FormGroup {
    return this.formBuilder.group({
      board: ['', Validators.required],
      qualification: ['', Validators.required]
    });
  }

  createSubjectsTaughtDetailed(): FormGroup {
    return this.formBuilder.group({
      subject: [{value: 'Please Select Subject', disabled: true}, Validators.required],
      qualification: ['Please Select Level', Validators.required],
      price: ['', Validators.required]
    });
  }

  onSubjectTaughtDetailedQualificationSelected(event, j: number, subject: any): void {
    console.log(subject);
    if (event.target.value === 'Please Select Level') {
      // @ts-ignore
      this.form.get('subjectsTaughtDetailed').at(j).get('subject').disable();
      return;
    }
    // @ts-ignore
    this.form.get('subjectsTaughtDetailed').at(j).get('subject').enable();
  }

  onAddQualification(): void {
    this.qualifications = this.form.get('qualifications') as FormArray;
    this.qualifications.push(this.createQualification());
  }

  onAddSubjectsTaughtDetailed(): void {
    this.subjectsTaughtDetailed = this.form.get('subjectsTaughtDetailed') as FormArray;
    this.subjectsTaughtDetailed.push(this.createSubjectsTaughtDetailed());
  }

  removeQualification(qualificationIndex: number) {
    const control = <FormArray>this.form.controls['qualifications'];
    control.removeAt(qualificationIndex);
  }

  removeSubject(subjectIndex: number) {
    const control = <FormArray>this.form.controls['subjectsTaughtDetailed'];
    control.removeAt(subjectIndex);
  }

  availabilityTriggered(state: boolean) {
    if (state) {
      this.isAvailable = true;
    } else {
      this.isAvailable = false;
    }
  }

  onFileSelected(event) {
    const image = this.UserImage.nativeElement;

    if (image.files && image.files[0]) {
      this.UserImage = image.files[0];
      this.location.nativeElement.value = this.UserImage.name;
      this.imageFileSelectedForValidation = true;
    }


  }


  getColorOfNo() {
    if (!this.isAvailable) {
      return {opacity: '1.0'};
    }
  }

  getColorOfYes() {
    if (this.isAvailable) {
      return {opacity: '1.0'};
    }
  }

  onSubmit() {
    this.teacher.fullName = this.form.controls.fullName.value;
    this.teacher.location = this.form.controls.location.value;
    this.teacher.highestDegree = this.form.controls.highestDegree.value;
    this.teacher.price = this.form.controls.price.value;
    this.teacher.shortDescription = this.form.controls.shortDescription.value;
    this.teacher.aboutMe = this.form.controls.aboutMe.value;
    this.teacher.aboutMySessions = this.form.controls.aboutMySessions.value;
    this.teacher.qualifications = this.form.value.qualifications;
    this.teacher.subjectsTaughtDetailed = this.form.value.subjectsTaughtDetailed;
    this.teacher.availability = {};
    this.teacher.availability.pre10 = this.form.value.availabilityPre10;
    this.teacher.availability.from10 = this.form.value.availabilityFrom10;
    this.teacher.availability.post10 = this.form.value.availabilityPost10;
    this.teacher.isAvailable = this.isAvailable;
    this.teacher.phoneNumber = this.form.value.phoneNumber;

    this.formData.append('teacher', new Blob([JSON.stringify(this.teacher)], {
      type: 'application/json'
    }));

    this.formData.append('file', this.UserImage);

    this.teacherService.addTeacher(this.formData).subscribe(
          res => {
              this.toastrService.success('Teacher is now listed.', 'You have added this teacher.');
              this.router.navigate(['/'], {relativeTo: this.activatedRoute});
          },
          err => {
              console.log(err);
              if (err.error.message.includes('phone_number_UNIQUE')) {
                this.toastrService.error('If there is any issue contact: 9851199619', 'Your entry was already entered.');
              } else {
                this.toastrService.error('Please contact the system engineer. 9851199619', 'Could not add this teacher.');
              }
          }
      );
  }

}
