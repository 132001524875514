import { Component, OnInit, Input } from '@angular/core';
import { ITeacher } from '../../models/teacher.model';

@Component({
  selector: 'app-teacher-thumbnail',
  templateUrl: './teacher-thumbnail.component.html',
  styleUrls: ['./teacher-thumbnail.component.css']
})
export class TeacherThumbnailComponent implements OnInit {
  @Input() teacher: ITeacher;

  constructor() { }

  ngOnInit() {
    
  }

}
