import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-star',
  templateUrl: './star.component.html',
  styleUrls: ['./star.component.css']
})
export class StarComponent implements OnInit {
  @Input() averageReview;
  reviewPercentage: number;

  constructor() { }

  ngOnInit() {
    this.reviewPercentage = (this.averageReview / 5) * 100;
  }

}
