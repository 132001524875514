import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';

import { AppComponent } from './app.component';
import { NavbarComponent } from './nav/navbar.component';
import { appRoutes } from '../routes';
import { TeachersListComponent } from './teacher/teachers-list/teachers-list.component';
import { TeacherThumbnailComponent } from './teacher/teacher-thumbnail/teacher-thumbnail.component';
import { AdvancedSearchComponent } from './teacher/teachers-list/advanced-search/advanced-search.component';
import { FooterComponent } from './footer/footer.component';
import { TeacherDetailsComponent } from './teacher/teacher-details/teacher-details.component';
import { TeacherService } from './services/teacher.service';
import { StarComponent } from './shared/star/star.component';
import { InterviewPanelComponent } from './teacher/teacher-details/interview-panel/interview-panel.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './cms/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { Interceptor } from './services/app.interceptor';
import { AuthenticationService } from './services/auth.service';
import { TokenStorage } from './services/token.storage';
import { RoleGuardService } from './services/role-guard.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LandPageComponent } from './land-page/land-page.component';
import { AgmCoreModule } from '@agm/core';
import { CeilingDistancePipe } from './shared/distanceCeiling.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpModule } from '@angular/http';
import { TeacherRegistrationComponent } from './teacher/teacher-registration/teacher-registration.component';
import {ImagesService} from './services/images.service';
import {OnlineClassStudentRegistrationService} from './services/onlineClassStudentRegistration.service';
import {OnlineTuitionLevelsComponent} from './onlineTuitionClasses/online-tuition-levels/online-tuition-levels.component';
import {OnlineTuitionLevelsService} from './services/onlineTuitionLevels.service';
import {OnlineTuitionSubjectsComponent} from './onlineTuitionClasses/online-tuition-subjects/online-tuition-subjects.component';
import {OnlineTuitionService} from './services/onlineTuition.service';
import {OnlineTuitionDaysComponent} from './onlineTuitionClasses/online-tuition-days/online-tuition-days.component';
import {OnlineTuitionStudentRegistrationComponent} from './onlineTuitionClasses/online-tuition-student-registration/online-tuition-student-registration.component';
import {AccountDeletionInfoComponent} from './cms/accountDeletionInfo/accountDeletionInfo';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    TeachersListComponent,
    TeacherThumbnailComponent,
    AdvancedSearchComponent,
    FooterComponent,
    TeacherDetailsComponent,
    StarComponent,
    InterviewPanelComponent,
    LoginComponent,
    AccountDeletionInfoComponent,
    OnlineTuitionLevelsComponent,
    OnlineTuitionSubjectsComponent,
    OnlineTuitionDaysComponent,
    OnlineTuitionStudentRegistrationComponent,
    LandPageComponent,
    CeilingDistancePipe,
    TeacherRegistrationComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    HttpModule,
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    AgmCoreModule.forRoot({
        apiKey: 'AIzaSyDwdx3x4fQbgoFK1uQ1tM2HAcIhTjv3V_c',
        libraries: ['places']
    }),
    NgxSpinnerModule,
    JwtModule.forRoot({
      config: {
          tokenGetter: tokenGetter,
          whitelistedDomains: ['http://139.59.4.226']
      }
  })
  ],
  providers: [
    OnlineTuitionLevelsService,
    OnlineTuitionService,
    TeacherService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    AuthenticationService,
    TokenStorage,
    OnlineClassStudentRegistrationService,
    RoleGuardService,
    ImagesService,
    JwtHelperService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function tokenGetter() {
  return localStorage.getItem('AuthToken');
}
