import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/auth.service';
import { TokenStorage } from '../../services/token.storage';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  message : string;

  constructor(private formBuilder: FormBuilder, private authService: AuthenticationService, private token: TokenStorage, private router: Router, private helper: JwtHelperService, public meta: Meta, public title: Title) { }

  ngOnInit() {
      // GOOGLE META
      this.title.setTitle( 'Login - TeachMandu');
      this.meta.updateTag({ name: 'description', content: 'Teacher Login for TeachMandu.' }); 
      // FACEBOOK META
      this.meta.updateTag({ property: 'og:title', content: 'Login - TeachMandu' });
      this.meta.updateTag({ property: 'og:type', content: 'article' });
      this.meta.updateTag({ property: 'og:image', content: 'assets/images/landpage-bg.jpg' });
      // this.meta.updateTag({ property: 'og:url', content: this.teacher.url });
      this.meta.updateTag({ property: 'og:description', content: 'Teacher Login for TeachMandu.' });


      this.initForm();
  }

  initForm(): void {
      this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required],
      })
  }

  get loginDetails(){
    return this.loginForm.controls;
  }

  login(): void {
    this.authService.attemptAuthentication(this.loginDetails.username.value, this.loginDetails.password.value).subscribe(
      data => {
        this.token.saveToken(data.token);
        this.authService.changeAuthentication();

        const tokenPayload = this.helper.decodeToken(data.token);

        if(tokenPayload.scopes === "ROLE_ADMIN"){
          this.router.navigate(['adminDashboard']);
        } else if (tokenPayload.scopes === "ROLE_USER") {
          this.router.navigate(['teacherDashboard']);
        } else {
          this.router.navigate(['/']);
        }
      },
    );
  }
}
