import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {OnlineRegisteredStudent} from '../../models/onlineRegisteredStudent';
import {ActivatedRoute, Router} from '@angular/router';
import {OnlineClassStudentRegistrationService} from '../../services/onlineClassStudentRegistration.service';

@Component({
  selector: 'app-online-tuition-student-registration',
  templateUrl: './online-tuition-student-registration.component.html',
  styleUrls: ['./online-tuition-student-registration.component.css']
})
export class OnlineTuitionStudentRegistrationComponent implements OnInit {

  form: FormGroup;
  onlineRegisteredStudent: OnlineRegisteredStudent = {fullName: '', level: '', phoneNumber: '', subject: ''};

  constructor(private onlineClassStudentRegistrationService: OnlineClassStudentRegistrationService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.forEach(
      (params) => {
        this.form = new FormGroup({
          fullName: new FormControl('', [Validators.required]),
          phoneNumber: new FormControl('', Validators.required),
          level: new FormControl(params.level, Validators.required),
          subject: new FormControl(params.subject, Validators.required),
          day: new FormControl('', Validators.required)
        });
      });
  }

  register() {
    let valid = true;
    if (!this.form.controls.fullName.value) {
      this.toastErrorMessage('Please provide your Full Name.');
      valid = false;
    }
    if (!this.form.controls.phoneNumber.value) {
      this.toastErrorMessage('Please provide your Phone Number.');
      valid = false;
    }
    if (!this.form.controls.level.value) {
      this.toastErrorMessage('Please provide the study Level.');
      valid = false;
    }
    if (!this.form.controls.subject.value) {
      this.toastErrorMessage('Please provide the study Subject.');
      valid = false;
    }
    if (!valid) { return; }

    this.onlineRegisteredStudent.fullName = this.form.controls.fullName.value;
    this.onlineRegisteredStudent.phoneNumber = this.form.controls.phoneNumber.value.toString();
    this.onlineRegisteredStudent.subject = this.form.controls.subject.value;
    this.onlineRegisteredStudent.level = this.form.controls.level.value;
    this.onlineRegisteredStudent.day = this.form.controls.day.value;

    this.onlineClassStudentRegistrationService.post(this.onlineRegisteredStudent)
      .subscribe(res => {
        this.toastSuccessMessage('You will be contacted soon.');
        this.router.navigate(['']);
      }, () => {
        this.toastErrorMessage('Something went Wrong!');
      });
  }

  toastErrorMessage(msg: string) {
    alert(msg);
  }

  toastSuccessMessage(msg: string) {
    alert(msg);
  }

}
