import { Injectable, Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';


const TOKEN_KEY = 'AuthToken';

@Injectable()
export class TokenStorage {

  sessionStorage;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  signOut() {
    if (isPlatformBrowser(this.platformId)) {
      window.sessionStorage.removeItem(TOKEN_KEY);
      window.sessionStorage.clear();
    }
  }

  public saveToken(token: string) {
    if (isPlatformBrowser(this.platformId)) {
      window.sessionStorage.removeItem(TOKEN_KEY);
      window.sessionStorage.setItem(TOKEN_KEY, token);
    }
  }

  public getToken(): string {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    return sessionStorage.getItem(TOKEN_KEY);
  }
}