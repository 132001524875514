import { Pipe } from '@angular/core';

@Pipe({
    name: 'distanceCeiling'
})
export class CeilingDistancePipe {

    transform(input: number) {
        let distance =  Math.ceil(input);
        if(distance > 1){
            return distance.toString() + " km from you"
        } else {
            return "Less than 1 km from you"
        }
    }

}
