import { Injectable, EventEmitter, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GlobalConstants } from "../shared/globalConstants";

@Injectable()
export class AuthenticationService {
    
    
    public authenticated: boolean = false;
    authenticatedEmitter: EventEmitter<boolean> = new EventEmitter();

    baseUrl: String = GlobalConstants.BASE_URL;

    constructor(private http: HttpClient){}



    attemptAuthentication(username: string, password: string){
        const credentials = {username: username, password: password};

        return this.http.post<any>(this.baseUrl + '/token', credentials);
    }

    changeAuthentication(){
        this.authenticated = !this.authenticated;
        this.authenticatedEmitter.emit(this.authenticated);
    }

}
